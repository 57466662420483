<template>
  <div>
    <head-section pageName="Dataset Mapping"></head-section>
    <v-card v-if="!loading">
      <v-card-title style="
          padding-top: 0% !important ;
          padding-bottom: 0% !important ;
          width: 98.5%;
        ">
        <span class="font--16 ml-8">Form Name : {{ formName }}</span>
        <v-spacer></v-spacer>
        <span style="width: 20%; height: 70%; margin-top: 0.8%">
          <v-select label="Select Dataset" :items="templates" v-model="selectedTemplate" @change="createTemplateFields"
            clearable dense filled></v-select>
        </span>
      </v-card-title>
      <div class="outer-box">
        <v-data-table :headers="headers" fixed-header height="520" :items="itemsWithSno" :items-per-page="10" style="
            border-left: #e0e0e0 solid 1px;
            border-right: #e0e0e0 solid 1px;
            border-bottom: #e0e0e0 solid 1px;
          ">
          <template slot="item" slot-scope="props">
            <tr>
              <td>{{ props.item.sno }}</td>
              <td>{{ props.item.key }}</td>
              <td>
                <v-select :items="getItems(props.item.ID)" item-text="data" @change="removeSelectItem(props.item)"
                  v-model="selectedObj[props.item.ID]" clearable solo dense>
                  <template v-slot:item="{ item }">
                    <div>
                      {{ item.data
                      }}<span v-if="item.required" class="red--text">*</span>
                    </div>
                  </template>
                </v-select>
              </td>
            </tr>
          </template>
          <template v-slot:footer.page-text class="text-right">
            <v-btn color="danger" class="mr-3" @click="onCancel" tile elevation="0">
              Cancel
            </v-btn>
            <v-btn color="primary" @click="onSubmit" :disabled="submitDisable" tile elevation="0">
              Submit
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <loader v-if="loading"></loader>
  </div>
</template>

<script>
import HeadSection from "../components/HeadSection.vue";
import Loader from "../components/Loader.vue";
export default {
  components: {
    HeadSection,
    Loader,
  },
  data: () => ({
    headers: [
      { text: "S. No.", value: "sno", class: "grey lighten-3 ", width: "7%" },
      {
        text: "Extracted Keys",
        value: "key",
        sortable: false,
        class: "grey lighten-3",
        width: "60%",
      },
      {
        text: "Template Fields",
        value: "known",
        sortable: false,
        class: "grey lighten-3",
        align: "center",
        width: "30%",
      },
    ],
    tableData: [],
    submitDisable: true,
    templatefield: [],
    selectedObj: {},
    templateData: [],
    templates: [],
    mappedData: [],
    selectedTemplate: "",
    onSubmitIndex: false,
    loading: false,
    // showTemplate: false,
    formName: "",
    cancelEnable: false,
  }),
  computed: {
    itemsWithSno() {
      return this.tableData.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  methods: {
    getItems(id) {
      if (this.templatefield.find((q) => q.id == id)) {
        return this.templatefield
          .filter((q) => q.id == null)
          .concat(this.templatefield.find((q) => q.id == id));
      } else {
        return this.templatefield.filter((q) => q.id == null);
      }
    },
    async getData() {
      this.loading = true;
      let params = {};
      this.$store.getters.form_id = this.$route.params.id;
      params.id = this.$route.params.id;
      params.currently_assigned = 2;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/dataset-mapping",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        let res = Object.values(response.data);
        let data = JSON.parse(res[0]);
        this.tableData = [...data];
        this.formName = res[1];
        await this.getTemplateData();
        if (this.$route.query.tid) this.getEditData();
      }
    },
    async getTemplateData() {
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/data-templates",
        {
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      let res = Object.values(response.data);
      if (response.data.status_code == 200 && !this.$route.query.tid)
        this.loading = false;

      let data = res[0];
      this.templateData = [...data];
      this.templateData.forEach((item) => {
        if (item.enable)
          this.templates.push(item.dataset_name);
      });
      this.templates.push("+ Create New");
    },
    createTemplateFields() {
      if (this.selectedTemplate == "+ Create New")
        this.$router.push("/create-dataset-template");
      this.templateData.forEach((item) => {
        if (item.dataset_name == this.selectedTemplate) {
          this.selectedObj = {};
          this.templatefield = [];
          item.validations.forEach((e) => {
            this.templatefield.push({
              id: null,
              data: e.field_name,
              required: e.required,
            });
          });
        }
      });
      // this.templatefield.forEach((e) => {
      //   if (e.required) {
      //     e.data = e.data + " *";
      //   }
      // });
      // debugger
      if (this.selectedTemplate && !this.$route.query.tid) {
        this.$store.state.isFormDirty = true;
      } else {
        this.$store.state.isFormDirty = false;
      }
      if (this.$route.query.tid) this.showSelectItem();
      // if(this.mappedData.length == 0 && this.$route.query.tid) {console.log("hello");this.$store.state.isFormDirty = true;}
    },
    showSelectItem() {
      this.mappedData.forEach((q) => {
        this.selectedObj[q.ID] = q.target_var;
      });
      this.tableData.forEach((e) => {
        let x = this.mappedData.filter((item) => item.ID == e.ID);
        this.templatefield.forEach((q) => {
          if (x[0]) {
            if (x[0].target_var == q.data) q.id = x[0].ID;
          }
        });
      });
      this.mappedData = [];
    },
    async getEditData() {
      this.showTemplate = true;
      let params = {};
      params.template_id = this.$route.query.tid;
      let response = await this.axios.get(
        process.env.VUE_APP_END_POINT + "/mapped-dataset",
        {
          params,
          headers: {
            accessToken: sessionStorage.accessToken,
          },
        }
      );
      if (response.data == "User Error") {
        alert("User Error! PLease login again");
        this.signOut();
      } else {
        let data = response.data.data;
        if (response.data.status_code == 200) this.loading = false;
        this.selectedTemplate = data.target_dataset;
        this.mappedData = data.mapped_data;
        this.createTemplateFields();
      }
    },
    async onSubmit() {
      this.loading = true;
      this.onSubmitIndex = true;
      this.$store.state.isFormDirty = false;
      this.$store.state.submit = true;
      const result = [];
      for (const [i, j] of Object.entries(this.selectedObj)) {
        let temp_field = "";
        this.tableData.forEach((item) => {
          if (item.ID == i) temp_field = item.key;
        });
        let maxLength = this.tableData.length;
        if (j != null) {
          result.push({
            required: false,
            target_var: j,
            ID: i,
            field: temp_field,
            lower_limit: parseInt(i) - 7 < 0 ? 0 : parseInt(i) - 7,
            upper_limit:
              parseInt(i) + 7 > maxLength - 1 ? maxLength - 1 : parseInt(i) + 7,
          });

          this.templatefield.forEach((e) => {
            result.forEach((item) => {
              if (e.id == item.ID) {
                item.required = e.required;
              }
            });
          });
        }
      }
      let params = {};
      let response = {};
      if (this.$route.query.tid) {
        params.template_id = this.$route.query.tid;
        params.template_name = this.selectedTemplate;
        response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/mapped-dataset",
          result,
          {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
      } else {
        params.id = this.$route.params.id;
        params.selected_dataset = this.selectedTemplate;
        response = await this.axios.post(
          process.env.VUE_APP_END_POINT + "/dataset-mapping",
          result,
          {
            params,
            headers: {
              accessToken: sessionStorage.accessToken,
            },
          }
        );
      }
      if (response.data.data == "Done" && this.$route.query.tid) {
        this.loading = false;
        this.$router.push("/form-templates");
        this.$store.state.submit = false;
      } else {
        this.loading = false;
        this.$store.getters.queueType = "Unassigned";
        this.$router.push("/form-queue");
        this.$store.state.submit = false;
      }
    },
    async onCancel() {
      this.cancelEnable = true;
      if (this.$store.state.isFormDirty) {
        await this.$router.push("/form-templates");
      } else {
        await this.$router.push("/form-templates");
      }
    },
    removeSelectItem(item) {
      this.templatefield.forEach((e) => {
        if (e.id == item.ID) {
          e.id = null;
        }
        if (e.data == this.selectedObj[item.ID]) {
          e.id = item.ID;
          return e;
        }
      });
      if (this.$route.query.tid) this.$store.state.isFormDirty = true;
      this.submitDisable = this.templatefield.find(
        (e) => e.required == true && e.id == null
      );
    },
    signOut() {
      // localStorage.clear();
      sessionStorage.clear();
      this.$msal.logout();
    },
    callOnMounted() {
      const params = {};
      params.id = this.$route.params.id;
      if (this.$route.query.tid) params.currently_assigned = 2;
      else params.currently_assigned = 1;
      let url = process.env.VUE_APP_END_POINT + "/dataset-mapping";
      this.$store.state.url = url;
      this.$store.state.params = params;
    },
  },
  // async beforeDestroy() {
  //   if (this.submitDisable && !this.cancelEnable) {
  //     this.cancel();
  //   }
  // },
  mounted() {
    if (this.$store.state.isFormDirty) {
      window.onbeforeunload = function () {
        return "Are you sure you want to close the window?";
      };
    }
    this.callOnMounted();
    this.getData();
  },
};
</script>

<style>
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
}
</style>
<style scoped>
/* .text-center {
  margin-top: 48%;
} */
.outer-box {
  margin-top: 0.5%;
  margin-left: 2.5%;
  width: 95%;
  height: 590px;
  font-size: 0.8em;
}

.light-grey {
  background-color: red;
}
</style>
